import React from 'react'
import Login from "../components/auth/login"

const Loginn = () => {
  return (
    <div>
      <Login/>
    </div>
  )
}

export default Loginn