import React from "react";
import Learning from "../components/programms/learningLab/index";

const Loginn = () => {
  return (
    <div>
      <Learning />
    </div>
  );
};

export default Loginn;
