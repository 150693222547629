import React from "react";
import Economical from "../components/programms/economical/index";

const Loginn = () => {
  return (
    <div>
      <Economical />
    </div>
  );
};

export default Loginn;
