import React, { useEffect, useState } from "react";
import Main from "./main/main";
import { useDispatch, useSelector } from "react-redux";
const Index = () => {
  const openSideBar = useSelector((state) => state.openSideBar);
  const dispatch = useDispatch();
  dispatch({ type: "SIDEBAR", num: "" });
  useEffect(() => {
    console.log("sidebar", openSideBar);
  }, [openSideBar]);

  return (
    <div className="flex flex-col">
      <Main />
    </div>
  );
};

export default Index;
