import back5 from "../../../Assets/back5.png";
import Card from "../../card/card";
import { Checkbox, Select } from "antd";
import { useState } from "react";
import Table from "./table";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
const { Option } = Select;
const Listing = () => {
  const dispatch= useDispatch();
  const [exporting, setExporting] = useState(false);
  const [exportTo, setExportTo] = useState(null);
  const [numberOfRecords, setNumberOfRecords] = useState("");
  const [sortBy, setSortBy] = useState(null);
  const [currentPageOnly, setCurrentPageOnly] = useState(false);
  const [exportColumnHeaders, setExportColumnHeaders] = useState(false);
  dispatch({ type: "SIDEBAR", num: "Learning Lab" })
  const router = useNavigate();
  const items = [
    {
      key: "1",

      label: `Total`,
      count: 875,
    },
    {
      key: "2",

      label: `Approved`,
      count: 256,
    },
    {
      key: "3",

      label: `Rejected`,
      count: 12,
    },
  ];

  return (
    <div className="w-full bg-gray-100 custom-scrollbar min-h-[95vh] overflow-auto pt-6 pb-6 md:pl-8 md:pr-8 pr-4 pl-4">
      <div className="w-full flex flex-col gap-4 p-4">
        <div className={`flex sm:flex-row flex-col justify-between `}>
          <div className="top-[2.8] cursor-pointer flex  items-center md:mb-0 mb-4  gap-2">
            <img
              onClick={() => {
                router("/DashBoard");
              }}
              className="w-[1.6rem]"
              src={back5}
              alt=""
            />
            <h2 className="font-bold sm:text-[1.8rem] text-[1.4rem]">
            Learning Lab
            </h2>
          </div>
          <div className="flex items-center gap-4">
            <button
              onClick={() => {
                console.log("Import File");
              }}
              className={`cursor-pointer shadow-lg text-black bg-white hover:bg-gray-50 pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-lg text-md `}
            >
              Import Data
            </button>

            <button
              onClick={() => {
                setExporting(true);
              }}
              className={` text-white cursor-pointer bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-lg text-md`}
            >
              Export Data
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap justify-between sm:ml-0 items-center gap-4 mt-[1rem] mb-8">
        {items.map((item) => (
          <Card key={item.key} item={item} />
        ))}
      </div>

      <Table />
      <div
        className={`${
          exporting ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-10`}
      >
        <div className="relative overflow-auto rounded-xl sm:w-[50%] w-[80%] p-6 flex items-center bg-white flex-col">
          <h1 className="text-[1.4rem] font-semibold mb-2">Export Data</h1>
          <div className="sm:gap-y-0 w-[100%] pl-10 pr-10">
            <div className="flex flex-col">
              <label
                htmlFor="exportTo"
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                Export To
              </label>
              <Select
                value={exportTo}
                onChange={setExportTo}
                placeholder="Export To"
                optionFilterProp="children"
                className="wow"
                style={{ marginBottom: "1.2rem" }}
                filterOption={(input, option) =>
                  (option?.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="middle"
              >
                <Option value="excel">Excel</Option>
                <Option value="pdf">Pdf</Option>
                <Option value="word">Word</Option>
              </Select>
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="numberOfRecords"
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                Number of Records
              </label>
              <input
                type="text"
                name="numberOfRecords"
                id="numberOfRecords"
                value={numberOfRecords}
                onChange={(e) => setNumberOfRecords(e.target.value)}
                placeholder="Number of Records"
                className="lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-2"
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="sortBy"
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                Sort By
              </label>
              <Select
                value={sortBy}
                onChange={setSortBy}
                placeholder="Sort By"
                optionFilterProp="children"
                className="wow"
                style={{ marginBottom: "1.2rem" }}
                filterOption={(input, option) =>
                  (option?.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="middle"
              >
                <Option value="asc">Ascending</Option>
                <Option value="desc">Descending</Option>
              </Select>
            </div>
            <div className="flex flex-col">
              <Checkbox
                className="checkk2 mt-2"
                checked={currentPageOnly}
                onChange={(e) => setCurrentPageOnly(e.target.checked)}
              >
                Current page Only
              </Checkbox>
            </div>
            <div className="flex flex-col">
              <Checkbox
                className="checkk2 mt-2"
                checked={exportColumnHeaders}
                onChange={(e) => setExportColumnHeaders(e.target.checked)}
              >
                Export Column Headers
              </Checkbox>
            </div>
          </div>
          <button
            type="button"
            onClick={() => {
              setExporting(false);
              console.log({
                exportTo,
                numberOfRecords,
                sortBy,
                currentPageOnly,
                exportColumnHeaders,
              });
            }}
            className="cursor-pointer mt-4 rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[40%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default Listing;
