import SeniorCitizenPensionTable from "./scp/scp";
import Medical from "./medical/medical";
import { useState, useEffect } from "react";
import axios from "axios";
import { Select } from "antd";
import logo from "../../../Assets/logo12.png";
import { useSelector, useDispatch } from "react-redux";
const { Option } = Select;
const Listing = () => {
  const [selectedProgram, setSelectedProgram] = useState("Senior Citizen");
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [reset, setReset] = useState(false);
  const [show, setShow] = useState(false);
  const [err, setErr] = useState(false);
  const token = useSelector((state) => state.token);

  const handleProgramChange = (value) => {
    setSelectedProgram(value);
  };
  useEffect(() => {
    let body = {
      program: selectedProgram,
      page: page,
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/records/view-records`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        console.log("Data", data);
        setData(data?.data?.records);
        setTotalPages(data?.data?.totalPages);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message?.description);
        setShow(true);
      });
  }, [selectedProgram, page, reset]);
  return (
    <div className="w-full bg-gray-100 custom-scrollbar min-h-[95vh] overflow-auto pt-6 pb-6 md:pl-8 md:pr-8 pr-4 pl-4">
      <Select
        defaultValue="Senior Citizen"
        size="large"
        className="w-full mb-2 font-semibold"
        onChange={handleProgramChange}
      >
        <Option value="Senior Citizen">Senior Citizen Pension</Option>
        <Option value="Medical">PA-Medical</Option>
      </Select>
      {selectedProgram === "Senior Citizen" ? (
        <SeniorCitizenPensionTable
          data={data}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          setReset={setReset}
          reset={reset}
        />
      ) : selectedProgram === "Medical" ? (
        <Medical
          data={data}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          setReset={setReset}
          reset={reset}
        />
      ) : (
        ""
      )}
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div class onClick={() => setShow(false)}>
            <img
              src={logo}
              alt=""
              className="cursor-pointer h-[4rem] w-[4rem]"
            />
          </div>
          <p className="text-center mt-[1rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            type="button"
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default Listing;
