import "./App.css";
import { Routes, Route } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";
import Login from "./pages/login";
import Protected from "./components/auth/protectedRoutes";
import Dash from "./pages/dashBoard";
import SCP from "./pages/scp";
import ChildProtection from "./pages/childProtection";
import LearningLab from "./pages/learningLab";
import Economical from "./pages/economical";
import Medical from "./pages/medical";
import PalmGeriatricHome from "./pages/palmGeriatric";
import PLWD from "./pages/plwd";
import WomenEmpowerment from "./pages/womenEmpowermint";
import RolesPrivi from "./pages/rolesPrivilages";
import Employee from "./pages/employees";
import AddEmployee from "./pages/addEmp";
import UpdateEmployee from "./pages/updateEmp";

function App() {
  const authState = useSelector((state) => state.authState);
  return (
    <div>
      <Routes>
        <Route path="/" element={<Login />} exact></Route>

        <Route
          path="/DashBoard"
          element={
            <Protected isSignedIn={authState}>
              <Dash />
            </Protected>
          }
          exact
        ></Route>

        <Route
          path="/Programs"
          element={
            <Protected isSignedIn={authState}>
              <SCP />
            </Protected>
          }
          exact
        ></Route>

        <Route
          path="/ChildProtection"
          element={
            <Protected isSignedIn={authState}>
              <ChildProtection />
            </Protected>
          }
          exact
        ></Route>

        <Route
          path="/LearningLab"
          element={
            <Protected isSignedIn={authState}>
              <LearningLab />
            </Protected>
          }
          exact
        ></Route>

        <Route
          path="/Economical"
          element={
            <Protected isSignedIn={authState}>
              <Economical />
            </Protected>
          }
          exact
        ></Route>

        <Route
          path="/Medical"
          element={
            <Protected isSignedIn={authState}>
              <Medical />
            </Protected>
          }
          exact
        ></Route>

        <Route
          path="/PalmGeriatricHome"
          element={
            <Protected isSignedIn={authState}>
              <PalmGeriatricHome />
            </Protected>
          }
          exact
        ></Route>

        <Route
          path="/PLWD"
          element={
            <Protected isSignedIn={authState}>
              <PLWD />
            </Protected>
          }
          exact
        ></Route>

        <Route
          path="/WomenEmpowerment"
          element={
            <Protected isSignedIn={authState}>
              <WomenEmpowerment />
            </Protected>
          }
          exact
        ></Route>

        <Route
          path="/Roles&Privilages"
          element={
            <Protected isSignedIn={authState}>
              <RolesPrivi />
            </Protected>
          }
          exact
        ></Route>

        <Route
          path="/Dashboard/Employees"
          element={
            <Protected isSignedIn={authState}>
              <Employee />
            </Protected>
          }
          exact
        ></Route>

        <Route
          path="/Dashboard/Employees/Add"
          element={
            <Protected isSignedIn={authState}>
              <AddEmployee />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Dashboard/Employees/Update"
          element={
            <Protected isSignedIn={authState}>
              <UpdateEmployee />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Dashboard/Employees/View"
          element={
            <Protected isSignedIn={authState}>
              <UpdateEmployee />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Dashboard/Profile"
          element={
            <Protected isSignedIn={authState}>
              <UpdateEmployee />
            </Protected>
          }
          exact
        ></Route>
      </Routes>
    </div>
  );
}

export default App;
