import React from 'react'
import PLWD from "../components/programms/plwd/index";

const Loginn = () => {
  return (
    <div>
      <PLWD/>
    </div>
  )
}

export default Loginn