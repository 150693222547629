import React, { useState } from "react";
import { Dropdown, Pagination, Popover } from "antd";
import logo from "../../../../Assets/logo12.png";
import { useSelector } from "react-redux";
import axios from "axios";
const Paget = ({ data, page, setPage, totalPages, setReset, reset }) => {
  const [deleteId, setDeleteId] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [show, setShow] = useState(false);
  const [err, setErr] = useState(false);
  const token = useSelector((state) => state.token);
  const items = [
    // {
    //   label: "View",
    //   key: "1",
    // },
    {
      label: "Edit",
      key: "2",
    },
    {
      label: "Delete",
      key: "3",
    },
  ];

  const columnHeaders = [
    "ID",
    "National ID",
    "Name",
    "Gender",
    "Date of Birth",
    "Ethnicity",
    "Email",
    // "Actions",
  ];
  const onClick = (key, item) => {
    if (key.key === "3") {
      setDeleteId(item?.id);
      setShowDelete(true);
    }
  };
  const Delete = () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/records/delete-record`,
        {
          id:  deleteId ,
          program: "Senior Citizen",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setShowDelete(false)
        setShowDelete(false);
        setReset(!reset);
      })
      .catch((err) => {
        setErr(err?.response?.data?.reason);
        setShow(true);
      });
  };
  return (
    <section className="w-full">
      <div className="mx-auto max-w-screen-xl">
        <div className="relative sm:rounded-lg overflow-hidden">
          <div className="flex flex-row items-center justify-end mb-4 gap-2 mt-3">
            <div className="w-full md:w-[20rem]">
              <form className="flex items-center">
                <label htmlFor="simple-search" className="sr-only">
                  Search
                </label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="simple-search"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2"
                    placeholder="Search"
                    required=""
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* table */}
        <div className="overflow-x-auto w-full h-full rounded-tl-lg rounded-tr-lg">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
              <tr>
                {columnHeaders.map((columnHeader) => (
                  <th
                    key={columnHeader}
                    scope="col"
                    className="px-4 py-3 text-white font-semibold tracking-wider whitespace-nowrap"
                  >
                    {columnHeader}
                  </th>
                ))}
              </tr>
            </thead>
            {data?.length === 0 ? (
              <tbody>
                <tr>
                  <td colSpan="8" className="text-center text-gray-500 text-lg">
                    <p className="p-10">No data found</p>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {data.map((item, index) => (
                  <tr
                    key={item.id}
                    className="border-b border-[1px] border-[#d9d9d970] bg-gray-50 hover:bg-gray-200 text-md cursor-pointer"
                  >
                    <th
                      scope="row"
                      className="px-4 py-3 font-medium text-gray-900  whitespace-nowrap "
                    >
                      {index + 1}.
                    </th>

                    <td className="px-4 py-3 whitespace-nowrap">
                      {item.national_id || "----"}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap">
                      {item.first_name || "----"}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap">
                      {item.sex || "----"}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap">
                      {item.dob
                        ? new Date(item.dob).toLocaleDateString()
                        : "----"}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap">
                      {item.ethnicity || "----"}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap">
                      {item.email || "----"}
                    </td>
                    {/* <td className="px-6 py-5 whitespace-nowrap">
                      <Dropdown
                        menu={{
                          items,
                          onClick: (key) => onClick(key, item),
                        }}
                        trigger={["click"]}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div className="w-[2.8rem] flex gap-1 cursor-pointer bg-[#f5f6fb] justify-center items-center h-[1.2rem] rounded-[1rem]">
                          <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                          <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                          <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                        </div>
                      </Dropdown>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        <nav
          className="flex bg-white flex-col h-[5.5rem] md:flex-row justify-center items-start md:items-center space-y-3 md:space-y-0 p-4 rounded-bl-lg rounded-br-lg"
          aria-label="Table navigation"
        >
          <span className="text-sm font-normal text-gray-500">
            Showing{" "}
            <span className="font-semibold text-gray-900 ">Page {page} </span>
            of{" "}
            <span className="font-semibold text-gray-900 ">{totalPages}</span>
          </span>
          <div className={`flex justify-end mt-7`}>
            <Pagination
              defaultCurrent={1}
              total={totalPages * 10}
              showSizeChanger={false}
              onChange={(page) => setPage(page)}
              current={page}
            />
          </div>
        </nav>
      </div>
      <div
        className={`${
          showDelete ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div class onClick={() => setShowDelete(false)}>
            <img
              src={logo}
              alt=""
              className="cursor-pointer h-[4rem] w-[4rem]"
            />
          </div>
          <p className="text-center mt-[1rem] mb-5">Are You sure you want to delete this record?</p>
          <div className="w-full flex items-center justify-center gap-2">
            <button
              onClick={() => setShowDelete(false)}
              type="button"
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[40%]"
            >
              No
            </button>
            <button
              onClick={Delete}
              type="button"
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[40%]"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div class onClick={() => setShow(false)}>
            <img
              src={logo}
              alt=""
              className="cursor-pointer h-[4rem] w-[4rem]"
            />
          </div>
          <p className="text-center mt-[1rem] mb-5">{err}</p>
          <div className="w-full flex items-center justify-center gap-2">
            <button
              onClick={() => setShow(false)}
              type="button"
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[40%]"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Paget;
