import React from 'react'
import PGH from "../components/programms/palmGeriatricHome";

const Loginn = () => {
  return (
    <div>
      <PGH/>
    </div>
  )
}

export default Loginn