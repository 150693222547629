import back5 from "../../Assets/back5.png";
import Card from "../card/card";
import { useNavigate } from "react-router-dom";
import Table from "./table";
const ListingE = () => {
  const router = useNavigate();
  const items = [
    {
      key: "1",

      label: `Total Employees`,
      count: 875,
    },
    {
      key: "2",

      label: `Approved Employees`,
      count: "234",
    },
    {
      key: "3",

      label: `Pending Employees`,
      count: "454",
    },
  ];
  return (
    <div className="w-full bg-gray-100 md:px-[5rem] px-6 custom-scrollbar min-h-screen py-[3rem]">
      <div className="w-full flex flex-col gap-4 p-4">
        <div className={`flex flex-row  justify-between `}>
          <div className="top-[2.8] cursor-pointer flex  items-center md:mb-0 mb-4  gap-2">
            <img
              onClick={() => {
                router("/DashBoard");
              }}
              className="w-[1.6rem]"
              src={back5}
              alt=""
            />
            <h2 className="font-bold sm:text-[1.8rem] text-[1.4rem]">
              Employees
            </h2>
          </div>
          <div className="flex items-center gap-4">
            <button
              onClick={() => {
                router("/Dashboard/Employees/Add");
              }}
              className={` text-white cursor-pointer bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-lg text-md`}
            >
              Add Employee
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap justify-between sm:ml-0 items-center gap-4 mt-[1rem] mb-8">
        {items.map((item) => (
          <Card key={item.key} item={item} />
        ))}
      </div>

      <Table />
    </div>
  );
};

export default ListingE;
