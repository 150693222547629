export const optionReducer = (state = "", action) => {
  switch (action.type) {
    case "Admin":
      return "Admin";
    case "SuperAdmin":
      return "SuperAdmin";
    case "Employee":
      return "Employee";
    case "":
      return "";
    default:
      return state;
  }
};
export const sidebar = (state = 1, action) => {
  switch (action.type) {
    case "SIDEBAR":
      return action.num;
    default:
      return state;
  }
};
export const openSideBar = (state = false, action) => {
  switch (action.type) {
    case "OPEN_SIDEBAR":
      return action.num;
    default:
      return state;
  }
};
export const resetReducer = (state = false, action) => {
  switch (action.type) {
    case "RESET":
      return !state;
    default:
      return state;
  }
};
export const user = (state = "", action) => {
  switch (action.type) {
    case "USER":
      return action.num;
    default:
      return state;
  }
};
