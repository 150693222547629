import React, { useState } from "react";
import { Route, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MenuFoldOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import { RightOutlined, DownOutlined } from "@ant-design/icons";
// import logo from "../../Assets/log.png";
import logo from "../../Assets/logo12.png";
import cp from "../../Assets/cp.png";
import cpa from "../../Assets/cpa.png";
import ll from "../../Assets/ll.png";
import lla from "../../Assets/lla.png";
import eco from "../../Assets/eco.png";
import ecoa from "../../Assets/ecoa.png";
import med from "../../Assets/med.png";
import meda from "../../Assets/meda.png";
import geriatric from "../../Assets/geriatric.png";
import geriatrica from "../../Assets/geriatrica.png";
import plwd from "../../Assets/plwd.png";
import plwda from "../../Assets/plwda.png";
import scp from "../../Assets/scp.png";
import scpa from "../../Assets/scpa.png";
import woman from "../../Assets/woman.png";
import womana from "../../Assets/womana.png";
import { Menu } from "antd";

const { SubMenu } = Menu;
const Sidebar = () => {
  const sidebar = useSelector((state) => state.sidebar);
  const [show, setShow] = useState(false);
  const [err, setErr] = useState(false);
  const dispatch = useDispatch();
  const router = useNavigate();
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  console.log("SideBar", sidebar);
  const toggleSubmenu = (key) => {
    if (activeMenuItem === key) {
      setShowSubmenu(!showSubmenu);
    } else {
      setShowSubmenu(true);
    }
    setActiveMenuItem(key);
  };

  const menuItems = [
    {
      key: "Child Protection",
      label: "Child Protection",
      children: [],
      Route: "/ChildProtection",
      active: cpa,
      inactive: cp,
    },
    {
      key: "Learning Lab",
      label: "Learning Lab",
      children: [],
      Route: "/LearningLab",
      active: lla,
      inactive: ll,
    },
    {
      key: "PA-Economical",
      label: "PA-Economical",
      children: [],
      Route: "/Economical",
      active: ecoa,
      inactive: eco,
    },
    {
      key: "PA-Medical",
      label: "PA-Medical",
      children: [],
      Route: "/Medical",
      active: meda,
      inactive: med,
    },
    {
      key: "Palm Geriatric Home",
      label: "Palm Geriatric Home",
      children: [],
      Route: "/PalmGeriatricHome",
      active: geriatrica,
      inactive: geriatric,
    },
    {
      key: "PLWD",
      label: "PLWD",
      children: [],
      Route: "/PLWD",
      active: plwda,
      inactive: plwd,
    },
    {
      key: "Senior Citizen Pension",
      label: "Senior Citizen Pension",
      children: [],
      Route: "/SCP",
      active: scpa,
      inactive: scp,
    },
    {
      key: "Women Empowerment",
      label: "Women Empowerment",
      children: [],
      Route: "/WomenEmpowerment",
      active: womana,
      inactive: woman,
    },
  ];
  // const menuItems = [
  //   {
  //     key: "Child Protection",
  //     label: "Child Protection",
  //     children: [],
  //     Route: "",
  //   },
  //   {
  //     key: "disability",
  //     label: "Disability",
  //     children: [
  //       { key: "Learning Lab", label: "Learning Lab" },
  //       { key: "permanent", label: "Permanent" },
  //       { key: "temporary", label: "Temporary" },
  //     ],
  //   },
  //   {
  //     key: "finance",
  //     label: "Financial",
  //     children: [],
  //     Route: "",
  //   },
  //   {
  //     key: "geriatric",
  //     label: "Palm Geriatric Home",
  //     children: [],
  //     Route: "",
  //   },
  //   {
  //     key: "Medical",
  //     label: "Medical",
  //     children: [],
  //     Route: "",
  //   },
  //   {
  //     key: "Senior Citizen Pension",
  //     label: "Senior Citizen Pension",
  //     children: [],
  //     Route: "/SCP",
  //   },
  //   {
  //     key: "women",
  //     label: "Women Empowerment",
  //     children: [],
  //     Route: "",
  //   },
  // ];

  return (
    <div className="bg-white rounded-lg h-[100%] flex space-between flex-col ">
      <aside id="logo-sidebar" aria-label="Sidebar" className="h-full">
        <div className="p-4">
          <MenuFoldOutlined
            className="text-2xl cursor-pointer text-gray-600 md:hidden"
            onClick={() => dispatch({ type: "OPEN_SIDEBAR", num: false })}
          />
        </div>
        <div className="px-4">
          <div className="flex flex-col justify-between">
            <div className="w-full h-full mb-5 flex items-center justify-center">
              <img
                onClick={() => {
                  router("/DashBoard");
                  dispatch({ type: "SIDEBAR", num: "1" });
                }}
                src={logo}
                alt=""
                className="w-[4rem] cursor-pointer h-[4rem]"
              />
            </div>
          </div>

          <ul className="space-y-2 font-medium text-primary text-[1rem]">
            {menuItems.map((menuItem) => (
              <li key={menuItem.key}>
                <a
                  href="#"
                  className={`flex text-[15px] items-center gap-2 p-[5px] rounded-lg ${
                    sidebar === menuItem.key
                      ? "bg-primary text-white"
                      : "text-gray-900 hover:bg-gray-100"
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("Menu", menuItem);
                    dispatch({ type: "SIDEBAR", num: menuItem.key });
                    router(menuItem?.Route);
                    toggleSubmenu(menuItem.key);
                    dispatch({ type: "OPEN_SIDEBAR", num: false });
                  }}
                >
                  <img
                    src={
                      sidebar === menuItem.key
                        ? menuItem.active
                        : menuItem.inactive
                    }
                    alt=""
                    className="w-5 h-5"
                  />
                  <span
                    className={
                      sidebar === menuItem.key ? "text-white" : "text-primary"
                    }
                  >
                    {menuItem.label}
                  </span>
                  {menuItem.children && menuItem.children.length > 0 && (
                    <>
                      {showSubmenu && activeMenuItem === "disability" ? (
                        <DownOutlined
                          className={
                            sidebar === menuItem.key
                              ? "text-white text-sm"
                              : "text-primary text-sm"
                          }
                        />
                      ) : (
                        <RightOutlined
                          className={
                            sidebar === menuItem.key
                              ? "text-white text-sm"
                              : "text-primary text-sm"
                          }
                        />
                      )}
                    </>
                  )}
                </a>

                {/* Render submenu if activeMenuItem is 'disability' and showSubmenu is true */}
                {showSubmenu && activeMenuItem === "disability" && (
                  <ul className="pl-4">
                    {menuItem.children.map((childItem) => (
                      <li
                        key={childItem.key}
                        onClick={() => {
                          dispatch({ type: "SIDEBAR", num: childItem.key });
                          console.log("Submenu clicked:", childItem.key);
                        }}
                      >
                        <a
                          href="#"
                          className={`flex items-center p-[5px] rounded-lg ${
                            sidebar === childItem.key
                              ? "bg-primary text-white"
                              : "text-gray-900 hover:bg-gray-100"
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            router("");
                          }}
                        >
                          <span
                            className={
                              sidebar === childItem.key
                                ? "text-white"
                                : "text-primary"
                            }
                          >
                            {childItem.label}
                          </span>
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      </aside>

      <p className="text-gray-400 mt-auto mb-6 text-center px-4 py-2">
        Version 1.2.0
      </p>
    </div>
  );
};

export default Sidebar;
