import ReactDOM from "react-dom/client";
import "./index.css";
import across from "../src/Assets/logo12.png";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { legacy_createStore as createStore } from "redux";
import allReducers from "./components/reducers";
import { Provider } from "react-redux";
import { Spin } from "antd";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import useAxiosLoader from "./utlis/AxiosLoader";
const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, allReducers);
const store = createStore(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
const persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById("root"));
const AppContainer = () => {
  const { loading, show, error, setShow } = useAxiosLoader();
  return (
    <>
      <Spin
        tip="Loading..."
        style={{ top: "18%", fontSize: "40px", color: "#e9b501" }}
        // fullscreen={"true"}
        size="large"
        spinning={loading}
        delay={100}
      >
        <div className="relative">
          <App />
        </div>
      </Spin>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div onClick={() => setShow(false)}>
            <img
              src={across}
              alt=""
              className="cursor-pointer h-[4rem] w-[4rem]"
            />
          </div>
          <p className="text-center text-[1rem] mt-[3rem] mb-5">{error}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </>
  );
};
root.render(
  // <React.StrictMode>
  // <React.StrictMode>
  <Router>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppContainer />
      </PersistGate>
    </Provider>
  </Router>
  // </React.StrictMode>,
  // document.getElementById("root")
  // </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
