import React from 'react'
import Women from "../components/programms/womenEmpowerment/index";

const Loginn = () => {
  return (
    <div>
      <Women/>
    </div>
  )
}

export default Loginn