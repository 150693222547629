import React from "react";
import Update from "../components/employees/update/updateEmp";
import Header from "../components/dash/header";
import MobHeader from "../components/dash/mobileHeader";
const Loginn = () => {
  return (
    <div>
      <div
        className={`w-full p-3 md:pl-[4rem] md:pr-[2rem] pl-4 pr-4 hidden md:flex`}
      >
        <Header />
      </div>
      <div className="md:hidden flex w-full ">
        <MobHeader />
      </div>
      <Update />
    </div>
  );
};

export default Loginn;
