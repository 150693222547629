import { Dropdown, Pagination, Popover } from "antd";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useLocation } from "react-router-dom";
const Paget = ({ search }) => {
  const location = useLocation();
  const dash = location.pathname;
  const router = useNavigate();
  const [sortBy, setSortBy] = useState("all");
  const [items, setItems] = useState([
    {
      label: "View ",
      key: "1",
    },
    {
      label: "Edit ",
      key: "3",
    },
    {
      label: "Delete ",
      key: "4",
    },
  ]);
  const [searchTerm, setSearchTerm] = useState("");

  const columnHeaders = [
    "SR. No",
    "ID",
    "Name",
    "Medication Amount",
    "Rental Amount",
    "Family Type",
    "Marital Status",
    "Employment Status",
  ];

  const allData = [
    {
      ID: 1,
      NID: "A123",
      Name: "John Doe",
      MedicationAmount: "$50",
      RentalAmount: "$800",
      FamilyType: "Nuclear",
      MaritalStatus: "Married",
      EmploymentStatus: "Employed",
    },
    {
      ID: 2,
      NID: "B456",
      Name: "Jane Smith",
      MedicationAmount: "$30",
      RentalAmount: "$700",
      FamilyType: "Joint",
      MaritalStatus: "Single",
      EmploymentStatus: "Unemployed",
    },
    {
      ID: 3,
      NID: "C789",
      Name: "Alice Johnson",
      MedicationAmount: "$20",
      RentalAmount: "$900",
      FamilyType: "Extended",
      MaritalStatus: "Divorced",
      EmploymentStatus: "Self-employed",
    },
    {
      ID: 4,
      NID: "D012",
      Name: "Robert Brown",
      MedicationAmount: "$60",
      RentalAmount: "$850",
      FamilyType: "Nuclear",
      MaritalStatus: "Married",
      EmploymentStatus: "Employed",
    },
    {
      ID: 5,
      NID: "E345",
      Name: "Emily Davis",
      MedicationAmount: "$40",
      RentalAmount: "$750",
      FamilyType: "Joint",
      MaritalStatus: "Single",
      EmploymentStatus: "Unemployed",
    },
    {
      ID: 6,
      NID: "F678",
      Name: "Michael Wilson",
      MedicationAmount: "$70",
      RentalAmount: "$950",
      FamilyType: "Extended",
      MaritalStatus: "Widowed",
      EmploymentStatus: "Retired",
    },
  ];

  return (
    <section className="w-full">
      <div className="mx-auto max-w-screen-xl ">
        <div className=" relative sm:rounded-lg overflow-hidden">
          <div className="flex flex-row items-center justify-end mb-4 gap-2 mt-3">
            <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
              <div className="relative flex items-center">
                <h1 className="mr-2 font-semibold text-[15px]">Sort By : </h1>
                <select
                  id="sortByDropdown"
                  className="w-full md:w-auto pl-4 pr-4 h-[40px] text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 :focus:ring-gray-700"
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                >
                  <option value="all">ALL</option>
                  <option value="name">Sort by Name</option>
                  <option value="date">Sort by Date</option>
                </select>
              </div>
            </div>

            <div className="w-full md:w-[20rem]">
              <form className="flex items-center">
                <label htmlFor="simple-search" className="sr-only">
                  Search
                </label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="simple-search"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2"
                    placeholder="Search"
                    required=""
                    value={search}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* table */}
        <div className="overflow-x-auto w-full h-full rounded-tl-lg rounded-tr-lg">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
              <tr>
                {columnHeaders.map((columnHeader) => (
                  <th
                    scope="col"
                    className="px-4 py-3 text-white font-semibold tracking-wider whitespace-nowrap"
                  >
                    {columnHeader}
                  </th>
                ))}
              </tr>
            </thead>
            {allData?.length === 0 ? (
              <tbody>
                <tr>
                  <td colSpan="9" className="text-center text-gray-500 text-lg">
                    <p className="p-10">No data found</p>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {allData.map((data, index) => (
                  <tr
                    onClick={() => {
                      "";
                    }}
                    className="border-b border-[1px] border-[#d9d9d970] bg-gray-50 hover:bg-gray-200 text-md cursor-pointer"
                  >
                    <th
                      scope="row"
                      className="px-4 py-3 font-medium text-gray-900  whitespace-nowrap "
                    >
                      {data.ID}.
                    </th>

                    <td className="px-4 py-3 whitespace-nowrap">
                      <div>{data.NID}</div>
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap">{data.Name}</td>
                    <td className="px-4 py-3 font-semibold whitespace-nowrap">
                      {data.MedicationAmount}
                    </td>
                    <td className="px-4 py-3 font-semibold whitespace-nowrap">
                      {data.RentalAmount}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap">
                      {data.FamilyType}
                    </td>

                    <td className="px-4 py-3 whitespace-nowrap">
                      {data.MaritalStatus}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap">
                      {data.EmploymentStatus}
                    </td>
                    {/* <td className="px-4 py-3 whitespace-nowrap">
                      {data.Booklet}
                    </td>

                    <td className="px-4 py-3 whitespace-nowrap">
                      <Popover
                        content={`${
                          data.Status === "pending" ? "In-Process" : ""
                        }`}
                        placement="bottomLeft"
                      >
                        <div
                          className={`border-none ${
                            data.Status === "pending"
                              ? "bg-[#2F84F1]"
                              : data?.Status === "Inactive"
                              ? "bg-[#D64242]"
                              : " bg-primary"
                          }  p-1 pl-5 pr-5 rounded-md text-white focus:border-none cursor-pointer capitalize`}
                        >
                          {data.Status}
                        </div>
                      </Popover>
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap">
                      {data.Created}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap">
                      <Dropdown
                        menu={{
                          items,
                          // onClick: (key) => onClick(key, item),
                        }}
                        trigger={["click"]}
                        onClick={(e) => {
                          // Stop the propagation of the click event
                          e.stopPropagation();
                        }}
                      >
                        <div className="w-[2.8rem] flex gap-1 cursor-pointer bg-[#f5f6fb] justify-center items-center h-[1.2rem] rounded-[1rem]">
                          <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                          <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                          <div className="h-[4px] w-[4px] rounded-full bg-gray-900"></div>
                        </div>
                      </Dropdown>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        <nav
          className="flex bg-white flex-col h-[5.5rem] md:flex-row justify-center items-start md:items-center space-y-3 md:space-y-0 p-4 rounded-bl-lg rounded-br-lg"
          aria-label="Table navigation"
        >
          <span className="text-sm font-normal text-gray-500">
            Showing{" "}
            <span className="font-semibold text-gray-900 ">Page {1} </span>
            of <span className="font-semibold text-gray-900 ">{100}</span>
          </span>
          <div className={`flex justify-end mt-7`}>
            <Pagination
              defaultCurrent={1}
              total={20 * 10}
              showSizeChanger={false}
              // onChange={""}
              current={1}
            />
          </div>
        </nav>
      </div>
    </section>
  );
};

export default Paget;
