import a from "../../Assets/a.jpg";
import b from "../../Assets/b.jpg";
import bcrypt from "bcryptjs";
import c from "../../Assets/c.jpg";
import d from "../../Assets/d.jpg";
import yes from "../../Assets/yes.png";
import employeeA from "../../Assets/employee-green.png";
import employeeI from "../../Assets/employee-grey.png";
import adminA from "../../Assets/admin-green.png";
import axios from "axios";
import adminI from "../../Assets/admin-grey.png";
import superadminA from "../../Assets/superadmin-green.png";
import superadminI from "../../Assets/superadmin-grey.png";
import logo from "../../Assets/logo12.png";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import { Carousel } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const Ww = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState("role");
  const option = useSelector((state) => state.option);
  const remMe = useSelector((state) => state.remMe);
  const [selectedCard, setSelectedCard] = useState(null);
  const [err, setErr] = useState("");
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [show, setShow] = useState(false);
  const contentStyle = {
    height: "100vh",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };
  const items = [
    {
      key: "2",
      title: `Employee`,
      active: employeeA,
      inActive: employeeI,
    },
    {
      key: "4",
      title: `Admin`,
      active: adminA,
      inActive: adminI,
    },
    {
      key: "5",
      title: `SuperAdmin`,
      active: superadminA,
      inActive: superadminI,
    },
  ];
  const hashPassword = async (password) => {
    const salt = await bcrypt.genSalt(10);
    return await bcrypt.hash(password, salt);
  };
  const comparePassword = async (plainPassword, hashedPassword) => {
    return await bcrypt.compare(plainPassword, hashedPassword);
  };
  const handleRememberMe = async () => {
    let credentials = {
      email: email,
      password: await hashPassword(password),
    };
    dispatch({ type: "REM-ME", num: credentials });
  };

  useEffect(() => {
    console.log("Testing", remMe);
    const retrieveCredentials = async () => {
      if (remMe?.email) {
        setEmail(remMe.email);
      }
      if (remMe?.password) {
        const isPasswordMatch = await comparePassword(password, remMe.password);
        if (isPasswordMatch) {
          setPassword(password);
        }
      }
    };
    retrieveCredentials();
  }, [remMe]);

  const optHandler = (e) => {
    if (e === "Employee") {
      dispatch({ type: "Employee" });
    }
    if (e === "Admin") {
      dispatch({ type: "Admin" });
    }
    if (e === "SuperAdmin") {
      dispatch({ type: "SuperAdmin" });
    }
  };
  const Login = () => {
    if (email !== "" && password !== "") {
      if (rememberMe) handleRememberMe();
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/v1/sign-in`, {
          password: password,
          email: email,
        })
        .then((data) => {
          dispatch({ type: "authStateSet", num: "true" });
          dispatch({ type: "USER", num: data?.data?.user?.roleName });
          dispatch({ type: "EMAIL", num: data?.data?.user?.email });
          dispatch({
            type: "tokenSet",
            num: data?.data?.token,
          });
          dispatch({ type: "dataSet", num: data?.data });
          router("/DashBoard");
        })
        .catch((err) => {
          console.log("err", err?.response?.data?.message);
          if (err?.response?.data?.message) {
            setErr(err?.response?.data?.message?.description);
          } else {
            setErr("Something went wrong please try again later!");
          }
          setShow(true);
        });
    } else {
      setErr("Please Enter Email and Password");
      setShow(true);
    }
  };

  return (
    <div className="bg-gray-50  w-full min-h-screen flex items-center">
      <div className="md:w-[55%] w-full bg-gray-200 flex items-center justify-center p-[2rem] md:rounded-tr-[2rem] shadow-md md:rounded-br-[2rem] pl-[2rem] pr-[2rem] min-h-screen">
        <div className="md:w-[68%] sm:w-[75%] w-[80%] h-[100%]  pb-[1rem] pt-[1rem] flex items-center justify-between flex-col">
          <div className="w-full  bg-gray-200">
            <div className="w-full flex items-center justify-center">
              <img
                onClick={() => {
                  dispatch({ type: "" });
                  setPage("role");
                }}
                src={logo}
                alt=""
                className="cursor-pointer w-[4rem] h-[4rem]"
              />
            </div>
            <h1 className="w-full text-center mt-2 text-xl font-bold">
              MHSSS PROGRAMS DASHBOARD
            </h1>
            <h1 className="w-full text-center mt-4 text-2xl font-bold">
              Good to see you!
            </h1>
            {page === "login" && (
              <p className="w-full text-center mb-4 mt-2 text-sm">
                Enter credentials to Sign in.
              </p>
            )}
            {page === "role" && (
              <p className="w-full text-center mb-4 mt-2 text-sm">
                Please Select a role.
              </p>
            )}
            {page === "login" && (
              <div className="flex pl-2 gap-2 mt-4 min-h-[12rem] pr-2 flex-col items-center justify-center gap-y-3 gap-x-5">
                <div className="w-full flex mb-3 flex-col">
                  <label
                    htmlFor="email"
                    className="text-[#596F96] bg-gray-200 text-[0.88rem] font-semibold mb-2"
                  >
                    Email *
                  </label>
                  <input
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    placeholder="Email"
                    className="bg-gray-200 p-2 border-b border-gray-400 mb-4"
                  />
                </div>
                <div className="flex w-full flex-col bg-gray-200">
                  <label
                    htmlFor="pass"
                    className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                  >
                    Password *
                  </label>
                  <div className="relative flex justify-between lg:text-[1.1rem] w-full mb-1">
                    <input
                      id="pass"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password"
                      className="bg-gray-200 p-2 border-b border-gray-400 w-full mb-4 pr-10"
                    />
                    <div
                      className="absolute inset-y-0 right-0 top-0  h-[2.7rem] flex items-center justify-center pr-3 cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <EyeOffIcon className="h-6 w-7 text-gray-600" />
                      ) : (
                        <EyeIcon className="h-6 w-7 text-gray-600" />
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-4 w-full justify-between cursor-pointer">
                  <div className="flex items-center w-full">
                    <input
                      type="checkbox"
                      id="rememberMe"
                      className="mr-2 cursor-pointer"
                      onClick={(e) => setRememberMe(e.target.checked)}
                      checked={rememberMe}
                    />
                    <label
                      htmlFor="rememberMe"
                      className="text-[0.8rem] cursor-pointer hover:underline font-semibold text-primary text-primary"
                    >
                      Remember me
                    </label>
                  </div>
                </div>
              </div>
            )}
            {page === "role" && (
              <div className="flex pl-2 gap-2 mt-4  min-h-[12rem] pr-2 flex-wrap items-center justify-center gap-y-6 gap-x-5">
                {items.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      optHandler(item.title);
                    }}
                    className={`w-[130px] bg-gray-50 relative flex gap-y-2 pt-10 pb-10 hover:text-[#020659] hover:border-[#020659] border border-[2px] flex flex-col items-center justify-center shadow-sm rounded-md h-[110px] cursor-pointer ${
                      option === item.title ? "border-primary" : ""
                    }`}
                    onMouseEnter={() => {
                      setSelectedCard(index);
                    }}
                    onMouseLeave={() => {
                      setSelectedCard(null);
                    }}
                  >
                    <img
                      src={yes}
                      alt=""
                      className={`absolute w-[24px] h-[24px] ${
                        option === item.title ? "" : "hidden"
                      } bottom-[-12px] text-primary bg-white rounded-full left-1/2 transform -translate-x-1/2`}
                    />
                    <div className="flex w-10 h-10 items-center justify-center">
                      <img
                        src={
                          option === item.title || selectedCard === index
                            ? item.active
                            : item.inActive
                        }
                        alt=""
                        className="h-full w-full rounded-full"
                      />
                    </div>
                    <div className="flex items-center justify-center hover:text-[#020659] flex-col w-full h-full">
                      <div className="text-center h-[30px] ">
                        <h1
                          className={`${
                            option === item.title || selectedCard === index
                              ? "text-primary"
                              : "text-black"
                          } text-[15px] hover:text-[#020659] font-bold`}
                          style={{ fontFamily: "poppins", fontWeight: "600" }}
                        >
                          {item?.title}
                        </h1>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {page === "login" && (
              <div className="w-full mt-10 flex items-center justify-center">
                <button
                  type="button"
                  onClick={Login}
                  className="justify-center flex w-full cursor-pointer bg-primary pt-2 pb-2 pl-[4rem] pr-[4rem] text-white rounded-xl"
                >
                  <span>Login</span>
                </button>
              </div>
            )}
            {page === "role" && (
              <div className="w-full mt-10 flex items-center justify-center">
                <button
                  type="button"
                  onClick={() => {
                    if (option !== "") setPage("login");
                    else {
                      setErr("Please Select a role to continue.");
                      setShow(true);
                    }
                  }}
                  className="justify-center flex w-full cursor-pointer bg-primary pt-2 pb-2 pl-[4rem] pr-[4rem] text-white rounded-xl"
                >
                  <span>Continue</span>
                </button>
              </div>
            )}
          </div>
          <div className="text-center text-sm mt-2.5 flex flex-col items-center">
            {/* <p>
              Don't have an account?{" "}
              <span className="text-primary hover:underline text-sm cursor-pointer">
                Register Now
              </span>
            </p> */}
          </div>
        </div>
      </div>
      <div className="md:w-[45%] w-0 h-full">
        <Carousel autoplay>
          <div style={contentStyle}>
            <img
              src={a}
              alt=""
              className="w-full  h-[100vh] cover flex items-center justify-center"
            />
          </div>
          <div style={contentStyle}>
            <img src={b} alt="" className="w-full h-[100vh]" />
          </div>
          <div className="h-[100vh]">
            <img src={c} alt="" className="w-full h-[100vh]" />
          </div>
          <div style={contentStyle}>
            <img src={d} alt="" className="w-full h-[100vh]" />
          </div>
        </Carousel>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div onClick={() => setShow(false)}>
            <img
              src={logo}
              alt=""
              className="cursor-pointer h-[4rem] w-[4rem]"
            />
          </div>
          <p className="text-center mt-[1rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            type="button"
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default Ww;
