import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import back5 from "../../Assets/back5.png";
import { Select, Checkbox } from "antd";
import axios from "axios";
import across from "../../Assets/log.png";
import { useSelector } from "react-redux";
const RolePrivliges = () => {
  const router = useNavigate();
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedPrivileges, setSelectedPrivileges] = useState([
    "View pensioner",
  ]);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState("");
  const [show, setShow] = useState("");
  // const email = useSelector((state) => state.email);
  const token = useSelector((state) => state.token);
  const [show1, setShow1] = useState("");
  const [privileges, setPrivileges] = useState([]);
  // const [prevBackUp, setPrevBackUp] = useState([]);
  // const [selectAll, setSelectAll] = useState("");
  // const toggleSelectAllPrivileges = (value) => {
  //   const newSelectAll = value === selectAll ? null : value;
  //   setSelectAll(newSelectAll);
  //   if (newSelectAll === "all") {
  //     const allPrivileges = privileges.map((privilege) => privilege);
  //     setSelectedPrivileges(allPrivileges);
  //   } else if (newSelectAll === "des") {
  //     setSelectedPrivileges([]);
  //   } else {
  //     setSelectedPrivileges(prevBackUp);
  //   }
  // };

  // useEffect(() => {
  //   setLoading(true);
  //   if (selectedRole === null) {
  //     setLoading(false);
  //     return;
  //   }
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_BASE_URL}/v1/privileges?empRole=${selectedRole}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
  //         },
  //       }
  //     )
  //     .then((data) => {
  //       const privileges = data?.data.map((item) => item.privilege);
  //       setPrevBackUp(privileges);
  //       setSelectedPrivileges(privileges);
  //       setLoading(false);
  //     })

  //     .catch(() => {
  //       setLoading(false);
  //     });
  // }, [selectedRole]);

  //Get subroles of employee
  // useEffect(() => {
  //   const api =
  //     user === "admin"
  //       ? "v1/privileges/list-roles-privileges/by-admin"
  //       : "v1/privileges/list-roles-privileges/by-superadmin";
  //   axios
  //     .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
  //       headers: {
  //         Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
  //       },
  //     })
  //     .then((data) => {
  //       const rolesArray = data.data.roles.map((role) => ({
  //         value: role,
  //         label: capitalizeWords(role),
  //       }));
  //       rolesArray.sort((a, b) => a.label.localeCompare(b.label));
  //       setRoles(rolesArray);
  //       setPrivileges(data.data.privileges);
  //       // setAllb(data.data);
  //     })

  //     .catch(() => {});
  // }, []);

  // const togglePrivilege = (privilege) => {
  //   let newSelectedPrivileges = [...selectedPrivileges];

  //   if (newSelectedPrivileges.includes(privilege)) {
  //     newSelectedPrivileges = newSelectedPrivileges.filter(
  //       (item) => item !== privilege
  //     );
  //   } else {
  //     newSelectedPrivileges.push(privilege);
  //   }

  //   setSelectedPrivileges(newSelectedPrivileges);
  // };

  // const assignedPrivileges = () => {
  //   const api =
  //     user === "admin"
  //       ? "v1/privileges/by-admin"
  //       : "v1/privileges/by-superadmin";
  //   if (selectedRole === null) {
  //     setErr("Please Select Role");
  //     setShow(true);
  //   } else {
  //     if (!selectedPrivileges.includes("View pensioner")) {
  //       setErr("Please Select View Pensioner Privilege");
  //       setShow(true);
  //     } else {
  //       setSelectedPrivileges(selectedPrivileges.filter((item) => item !== ""));
  //       axios
  //         .post(
  //           `${process.env.REACT_APP_BASE_URL}/${api}`,
  //           {
  //             role: selectedRole,
  //             privileges: selectedPrivileges,
  //           },
  //           {
  //             headers: {
  //               Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
  //             },
  //           }
  //         )
  //         .then(() => {
  //           setShow1(true);
  //           setErr("Added Sucessfully");
  //           // setSelectedPrivileges([]);
  //           // setSelectedRole(null);
  //           // Set a timeout to close the modal after 2 seconds
  //           setTimeout(() => {
  //             setShow1(false);
  //           }, 1000); // 2000 milliseconds = 2 seconds
  //         })
  //         .catch((err) => {
  //           setErr(err?.message);
  //           setShow(true);
  //         });
  //     }
  //   }
  // };
  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/role-privileges`,
        {
          roleName: "Super Admin",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        console.log("pri", data);
        const privilegesArray = data?.data[0]?.privileges.map(
          (privilege) => privilege.privilegeName
        );
        setPrivileges(privilegesArray);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message?.description);
        setShow(true);
        setLoading(false)
      });
  }, []);
  useEffect(() => {
    if (selectedRole) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/role-privileges`,
          {
            roleName: selectedRole,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((data) => {
          console.log("pri", data);
          const privilegesArray = data?.data[0]?.privileges.map(
            (privilege) => privilege.privilegeName
          );
          // setPrivileges(privilegesArray);
          setSelectedPrivileges(privilegesArray);
        })
        .catch((err) => {
          setErr(err?.response?.data?.message?.description);
          setShow(true);
        });
    }
  }, [selectedRole]);
  const roles = [
    { value: "Admin", label: "Admin" },
    { value: "Employee-Clerical", label: "Employee Clerical" },
    { value: "Employee-Financial", label: "Employee Financial" },
    { value: "Employee-Management", label: "Employee Management" },
  ];

  return (
    <div>
      <div className="md:p-10 md:pl-[7rem] md:pr-[7rem] p-4">
        <div>
          <div className="relative flex items-center mt-4">
            <div className="cursor-pointer">
              <img
                onClick={() => {
                  router("/DashBoard");
                }}
                className="sm:w-[1.7rem] w-[1.2rem]"
                src={back5}
                alt=""
              />
            </div>
            <h2 className="font-bold sm:text-[1.8rem] ml-[1rem] text-[1.2rem]">
              Roles & Privileges
            </h2>
          </div>
          <div className="sm:ml-0  w-full mt-[2rem] mb-8 bg-slate-50 p-[35px] rounded-2xl relative">
            <h1 className="text-center text-[1.6rem] font-bold mb-2">
              {/* Assign Privileges to Roles */}
              Roles & Privileges
            </h1>
            <div className="mt-3 ">
              <h2 className="mb-4 text-[1rem] font-semibold">Select Role</h2>
              <Select
                placeholder="Role"
                optionFilterProp="children"
                className="wow w-full"
                style={{
                  marginBottom: "1.2rem",
                  //   pointerEvents: `${allDis ? "none" : "auto"}`,
                }}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="middle"
                onChange={(value) => {
                  setSelectedRole(value);
                  // setSelectAll("");
                }}
                value={selectedRole}
                options={roles}
              />
              <h2 className="mb-4 text-[1rem] font-semibold">Privileges</h2>

              {/* <div className="flex  mb-4 gap-5">
                <Checkbox
                  checked={selectAll === "all"}
                  onChange={() => {
                    toggleSelectAllPrivileges("all");
                  }}
                  className={`checkk4`}
                >
                  <p className="text-gray-700 text-[1rem] font-semibold">
                    Select All
                  </p>
                </Checkbox>
                <Checkbox
                  checked={selectAll === "des"}
                  onChange={() => {
                    toggleSelectAllPrivileges("des");
                  }}
                  className={`checkk4`}
                >
                  <p className="text-gray-700 text-[1rem] font-semibold">
                    Deselect All
                  </p>
                </Checkbox>
              </div> */}
              {loading ? (
                <div className="flex justify-center items-center h-full">
                  {/* <Spin size="large" className="text-4xl text-primary-500 h-20 w-20" /> */}
                </div>
              ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-[3.125rem]">
                  {privileges &&
                    privileges.map((privilege) => (
                      <div
                        key={privilege}
                        className="p-2 flex items-center border shadow-sm rounded-md hover:bg-gray-100 transition duration-300 ease-in-out bg-white"
                      >
                        {/* <label className="text-sm font-semibold text-gray-700 flex items-center"> */}
                        <Checkbox
                          type="checkbox"
                          className={`${
                            privilege === "View pensioner"
                              ? "pointer-events-none"
                              : ""
                          } text-slate-500 text-sm font-semibold text-gray-700 flex items-center`}
                          checked={selectedPrivileges.includes(privilege)}
                          // onChange={() => togglePrivilege(privilege)}

                          // onChange={()=>setSinglePrivilege(privilege)}
                          // checked={singlePrivilege === privilege}
                        >
                          {privilege}
                          {/* </label> */}
                        </Checkbox>
                      </div>
                    ))}
                </div>
              )}
            </div>
            {/* <div className="bottom-5 w-full flex items-center sm:justify-end justify-center">
              <button className="lg:text-[1.1rem]  bg-gradient-to-tr bg-[#DE2827] text-white rounded-[0.5rem] p-2  pr-8 pl-8 ">
                Cancel
              </button>
              <button
                className="lg:text-[1.1rem]  bg-gradient-to-tr ml-4 bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8"
                // onClick={""}
              >
                Assigned
              </button>
            </div> */}

            {/* <div className="absolute bottom-5 right-10  flex">
                            <button
                                className="lg:text-[1.1rem]  bg-gradient-to-tr ml-4 bg-[#DE2827] text-white rounded-[0.5rem] p-2  pr-8 pl-8 "
                            >
                                Cancel
                            </button>
                            <button

                                className="lg:text-[1.1rem]  bg-gradient-to-tr ml-4 bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8"
                                onClick={assignedPrivileges}
                            >
                                Assigned
                            </button>
                        </div> */}
          </div>
        </div>
      </div>

      {/* Error Modal */}
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center text-[1.1rem] mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>

      <div
        className={`${
          show1 ? "" : "hidden"
        } w-[100%] h-[100%] z-[100] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          {/* <div
                        className="absolute top-[-11%] left-[42%]"
                        onClick={() => setShow1(false)}
                    >
                        <img src={across} alt="" />
                    </div> */}
          <p className="text-center text-[1.1rem] mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow1(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 bg-gradient-to-tr ml-4 bg-primary text-white w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default RolePrivliges;
