// import React, { useEffect, useState } from "react";
// import Header from "../../dash/header";
// import Sidebar from "../../dash/sidebar";
// import Main from "./main";

// import { useDispatch } from "react-redux";

// const Index = () => {
//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch({ type: "SIDEBAR", num: "Senior Citizen Pension" });
//   }, []);

//   return (
//     <>
//       <div className="mobile-screen w-screen bg-gray-200 h-screen flex">
//         <div className="bg-white w-[17%] border-r h-screen">
//           <Sidebar />
//         </div>
//         <div className="flex flex-col w-[83%]">
//           <div>
//             <Header className="w-full" />
//           </div>
//           <main className="flex-grow p-0 w-full">
//             <Main />
//           </main>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Index;

import React, { useEffect, useState } from "react";
import Header from "../../dash/header";
import MobHeader from "../../dash/mobileHeader";
import { Popover } from "antd";
import back5 from "../../../Assets/back5.png";
import { useNavigate } from "react-router-dom";
import notification from "../../../Assets/bell.png";
import setting from "../../../Assets/setting.png";
import chat from "../../../Assets/message.png";
import analytics from "../../../Assets/analytics.png";
import Sidebar from "../../dash/sidebar";
import Main from "./main";
import { useDispatch, useSelector } from "react-redux";
import { MenuUnfoldOutlined } from "@ant-design/icons";
const profile =
  "https://pics.craiyon.com/2023-11-18/0fp0vpAASx63U9NHLQzayg.webp";
const Index = () => {
  const router = useNavigate();
  const openSideBar = useSelector((state) => state.openSideBar);
  const dispatch = useDispatch();
  console.log("Tester", openSideBar);
  const w = window.innerWidth;

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    console.log("sidebar", openSideBar);
  }, [openSideBar]);

  return (
    <div className="w-full bg-gray-100 md:px-[5rem] px-6 custom-scrollbar min-h-screen py-[3rem]">
      <div className="w-full flex flex-col gap-4 p-4">
        <div className={`flex flex-row  justify-between `}>
          <div className="top-[2.8] cursor-pointer flex  items-center md:mb-0 mb-4  gap-2">
            <img
              onClick={() => {
                router("/DashBoard");
              }}
              className="w-[1.6rem]"
              src={back5}
              alt=""
            />
            <h2 className="font-bold sm:text-[1.8rem] text-[1.4rem]">
              Programs
            </h2>
          </div>
          <div className="flex items-center gap-4"></div>
        </div>
      </div>
      <Main />
    </div>
  );
};

export default Index;
