import {
  optionReducer,
  sidebar,
  openSideBar,
  resetReducer,
  user,
} from "./option";
import {
  authStateReducer,
  tokenReducer,
  idSet,
  DataUser,
  caseId,
  email,
  EData,
  remMe,
} from "./data";
import { combineReducers } from "redux";

const allReducers = combineReducers({
  option: optionReducer,
  sidebar: sidebar,
  authState: authStateReducer,
  token: tokenReducer,
  reset: resetReducer,
  idSet: idSet,
  dataUser: DataUser,
  caseId: caseId,
  remMe: remMe,
  user: user,
  email: email,
  data: EData,
  openSideBar: openSideBar,
});

export default allReducers;
