import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Main = () => {
  const [url, setUrl] = useState("");
  const dataUser = useSelector((state) => state.dataUser);
  console.log("User", dataUser);

  useEffect(() => {
    if (dataUser?.user?.roleName === "Employee-Clerical") {
      setUrl("https://app.powerbi.com/view?r=eyJrIjoiZDA3ODEyMmMtYzhiNS00ZjMxLWI1NzMtZDU4NWRlZTJkOWM5IiwidCI6IjE1Y2M4YzAxLTQ0M2EtNDNmOS1iODQxLTM3YjI3MjUxZjFjYyJ9");
    } else {
      setUrl("https://app.powerbi.com/view?r=eyJrIjoiZWIzYzczNWMtNWQ1ZC00NWJiLWFmNGYtMTVhNzAwMzM3NDA3IiwidCI6IjE1Y2M4YzAxLTQ0M2EtNDNmOS1iODQxLTM3YjI3MjUxZjFjYyJ9");
    }
  }, [dataUser]);

  return (
    <div className="">
      {url && (
        <iframe
          className="h-[87.5vh]"
          title="Mohsss_Report"
          width="100%"
          src={url}
          frameBorder="0"
          allowFullScreen={true}
        ></iframe>
      )}
    </div>
  );
};

export default Main;
