import React from 'react'
import Medical from "../components/programms/medical/index";

const Loginn = () => {
  return (
    <div>
      <Medical/>
    </div>
  )
}

export default Loginn