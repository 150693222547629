// import React, { useEffect, useState } from "react";
// import Header from "../../dash/header";
// import Sidebar from "../../dash/sidebar";
// import Main from "./main";

// import { useDispatch } from "react-redux";

// const Index = () => {
//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch({ type: "SIDEBAR", num: "Senior Citizen Pension" });
//   }, []);

//   return (
//     <>
//       <div className="mobile-screen w-screen bg-gray-200 h-screen flex">
//         <div className="bg-white w-[17%] border-r h-screen">
//           <Sidebar />
//         </div>
//         <div className="flex flex-col w-[83%]">
//           <div>
//             <Header className="w-full" />
//           </div>
//           <main className="flex-grow p-0 w-full">
//             <Main />
//           </main>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Index;

import React, { useEffect, useState } from "react";
import Header from "../../dash/header";
import MobHeader from "../../dash/mobileHeader";
import Sidebar from "../../dash/sidebar";
import Main from "./main";
import { useSelector } from "react-redux";
const Index = () => {
  const openSideBar = useSelector((state) => state.openSideBar);
  console.log("Tester", openSideBar);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    console.log("sidebar", openSideBar);
  }, [openSideBar]);

  return (
    <div
      className={`${
        windowSize?.width <= 900
          ? "h-screen flex flex-col"
          : "flex flex-row h-screen bg-[#D9EFED] w-full"
      }`}
    >
      {windowSize?.width <= 900 ? (
        <>
          <header className="flex items-center pr-4 justify-between bg-primary py-2 px-2 gap-4 text-white">
            <MobHeader />
          </header>
          {openSideBar && (
            <div className="fixed left-0 top-0 bottom-0 z-20 bg-white w-4/5">
              <Sidebar />
            </div>
          )}
        </>
      ) : (
        <aside
          className="w-[17%] h-[20] shadow-md border-r"
          style={{
            backgroundColor: "white",
            boxShadow:
              "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
          }}
        >
          <Sidebar />
        </aside>
      )}
      <div
        className={`${
          windowSize?.width <= 900
            ? "flex-grow  overflow-y-auto"
            : "flex flex-col w-[83%] "
        }`}
      >
        <header
          className={`h-16 shrink-0 md:flex rounded-lg ${
            windowSize?.width <= 768 ? "hidden" : ""
          }`}
        >
          <Header className="w-full" />
        </header>
        <div
          className={`${
            windowSize?.width <= 900
              ? ""
              : "overflow-auto"
          }`}
        >
          <Main />
        </div>
      </div>
    </div>
  );
};

export default Index;
