import React from 'react'
import Child from "../components/programms/childProtection/index"

const Loginn = () => {
  return (
    <div>
      <Child/>
    </div>
  )
}

export default Loginn