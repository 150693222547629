// useAxiosLoader.js
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
const useAxiosLoader = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const [axiosState, setAxiosState] = useState({
    loading: false,
    ref: false,
    show: false,
    error: "",
  });
  const setLoading = (value) => {
    setAxiosState((prevState) => ({
      ...prevState,
      loading: value,
    }));
  };
  // const setRef = (value) => {
  //     setAxiosState((prevState) => ({
  //         ...prevState,
  //         ref: value,
  //     }));
  // };
  const setShow = (value) => {
    setAxiosState((prevState) => ({
      ...prevState,
      show: value,
    }));
  };
  const setError = (value) => {
    setAxiosState((prevState) => ({
      ...prevState,
      error: value,
    }));
  };
  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use((request) => {
      setLoading(true);
      return request;
    });

    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        setLoading(false);
        return response;
      },
      (error) => {
        setLoading(false);
        if (
          error?.response?.data?.message?.description ===
          "Your session has expired."
        ) {
          dispatch({ type: "authStateSet", num: "false" });
          setError("Session Expired: Please Login Again");
          setShow(true);
          router("/");
        }
        return Promise.reject(error);
      }
    );

    // Cleanup function
    return () => {
      // Remove the interceptors when the component unmounts
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return { ...axiosState, setShow, setError };
};

export default useAxiosLoader;
